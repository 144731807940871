/**
 * @typedef {[
*   className: string,
*   eventType: string,
*   handler: (event: Event) => void
* ]} EventTuple
*/

/**
* Forces the input to be numeric only.
*
* @param {Event} event - The input event.
* @returns {void}
*/
export function forceInputNumeric(event) {
 const target = event.target;
 const start = target.selectionStart;
 const end = target.selectionEnd;

 target.value = target.value.replace(/[^\d\s]/g, '');
 target.setSelectionRange(start, end);
}

/**
* Forces the input to be numeric and hyphen only.
*
* @param {Event} event - The input event.
* @returns {void}
*/
export function forceInputNumericAndHyphen(event) {
 const target = event.target;
 const start = target.selectionStart;
 const end = target.selectionEnd;

 target.value = target.value.replace(/[^0-9-]/g, '');
 target.setSelectionRange(start, end);
}

/**
* Forces the input to be alphanumeric only.
*
* @param {Event} event - The input event.
* @returns {void}
*/
export function forceInputAlphnumeric(event) {
 const target = event.target;
 const start = target.selectionStart;
 const end = target.selectionEnd;

 target.value = target.value.replace(/[^a-zA-Z\d\s]/g, '');
 target.setSelectionRange(start, end);
}

/**
* Forces the input to be alphanumeric and allow left chevron.
*
* @param {Event} event - The input event.
* @returns {void}
*/
export function forceInputAlphnumericLeftChevron(event) {
 const target = event.target;
 const start = target.selectionStart;
 const end = target.selectionEnd;

 target.value = target.value.replace(/[^a-zA-Z<\d\s]/g, '');
 target.setSelectionRange(start, end);
}

/**
* Forces the input to be uppercase.
*
* @param {Event} event - The input event.
* @returns {void}
*/
export function forceInputUppercase(event) {
 const target = event.target;
 const start = target.selectionStart;
 const end = target.selectionEnd;

 target.value = target.value.toLocaleUpperCase();
 target.setSelectionRange(start, end);
}

/**
* Prevents spaces from being entered.
*
* @param {KeyboardEvent} event - The keypress event.
* @returns {void}
*/
export function forceInputNoSpaces(event) {
 const key = event.key;

 if (key === ' ') {
   event.preventDefault();
 }
}

/**
* Prevent the default event action, such as submit or paste.
*
* @param {Event} event - The submit or paste event, etc.
* @returns {boolean} Returns false to prevent event propagation.
*/
export function preventEventDefault(event) {
 event.preventDefault();

 return false;
}

/**
* Registers event listeners for elements with a specific class name.
*
* @param {string} className - The class name of the elements.
* @param {string} type - The event type.
* @param {EventListener} callback - The event listener callback.
*/
export function registerEventsByClassName(className, type, callback) {
 const elements = document.getElementsByClassName(className);

 for (const element of elements) {
   element.addEventListener(type, callback);
 }
}

/** @type {EventTuple[]} */
const events = [
 ['uppercase', 'input', forceInputUppercase],
 ['alphanumericOnly', 'input', forceInputAlphnumeric],
 ['alphanumericChevronInputs', 'input', forceInputAlphnumericLeftChevron],
 ['numericOnly', 'input', forceInputNumeric],
 ['numericAndHyphenOnly', 'input', forceInputNumericAndHyphen],
 ['noSpace', 'keypress', forceInputNoSpaces],
 ['noPaste', 'paste', preventEventDefault],
 ['disableOnSubmit', 'submit', preventEventDefault],
];

/**
* Registers input listeners for the given elements
*
* @returns {void}
*/
export function registerInputListeners() {
 for (const [className, type, callback] of events) {
   registerEventsByClassName(className, type, callback);
 }
}

export default {
 registerInputListeners,
};
